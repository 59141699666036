import { dateTimeUtils } from '@cocoonspace/shared/utils/date-time-utils'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Pie, PieChart } from 'recharts'
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from '~/components/ui/card'
import {
	type ChartConfig,
	ChartContainer,
	ChartLegend,
	ChartLegendContent,
	ChartTooltip,
	ChartTooltipContent,
} from '~/components/ui/chart'
import { Skeleton } from '~/components/ui/skeleton'
import { useListEntityEntries } from '~/lib/admin-sdk-js/domains/entries/entries'
import { PercentIncrease } from './percent-increase'

const chartConfig = {
	meeting: {
		label: 'Meeting',
		color: 'var(--type-event)',
	},
	plateform: {
		label: 'Plateforme',
		color: 'var(--type-presta)',
	},
	visit: {
		label: 'Visite',
		color: 'var(--type-visit)',
	},
	quotation: {
		label: 'Devis',
		color: 'var(--type-quotation)',
	},
	desk: {
		label: 'Desk',
		color: 'var(--type-desk)',
	},
} satisfies ChartConfig

export const EventsRecapStats = () => {
	const { t } = useTranslation()

	const events = useListEntityEntries('events', { page_size: 1000 })

	const eventsThisMonth = useListEntityEntries('events', {
		page_size: 1,
		// @ts-expect-error
		'day.start': dateTimeUtils().set('date', 1).customFormat('dateTech'),
		'day.end': dateTimeUtils().endOf('month').customFormat('dateTech'),
	})

	const eventsPrevMonth = useListEntityEntries('events', {
		page_size: 1,
		// @ts-expect-error
		'day.start': dateTimeUtils()
			.set('date', 1)
			.set('month', -1)
			.customFormat('dateTech'),
		'day.end': dateTimeUtils()
			.set('month', -1)
			.endOf('month')
			.customFormat('dateTech'),
	})

	const totalDayPrice = useMemo(() => {
		return (
			events.data?.data?.reduce(
				(acc, event) => acc + (event.total_price || 0),
				0,
			) || 0
		)
	}, [events])

	const chartData = useMemo(() => {
		const data = events.data?.data
		const meetings = data?.filter(({ type }) => type === 'meeting').length
		const plateform = data?.filter(({ channel }) => !!channel).length
		const visits = data?.filter(({ type }) => type === 'visit').length
		const desks = data?.filter(({ type }) => type === 'desk').length
		const quotations = data?.filter(({ type }) => type === 'quotation').length

		return [
			{ name: 'meeting', value: meetings || 0 },
			{ name: 'plateform', value: plateform || 0 },
			{ name: 'quotation', value: quotations || 0 },
			{ name: 'visit', value: visits || 0 },
			{ name: 'desk', value: desks || 0 },
		].map((item) => ({
			...item,
			fill: chartConfig[item.name as keyof typeof chartConfig]?.color,
		}))
	}, [events])

	return (
		<Card>
			<CardHeader className='flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row'>
				<div className='flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6'>
					<CardTitle>Évènements</CardTitle>

					<CardDescription>
						Évènements de la journée par type, et total du mois (comparé au mois
						précédent).
					</CardDescription>
				</div>

				<div className='flex'>
					<div className='relative z-30 flex min-w-[152px] flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l sm:border-t-0 sm:border-l'>
						<span className='text-muted-foreground text-xs'>
							Total aujourd'hui
						</span>

						<span className='flex items-end gap-2 font-bold text-lg leading-none sm:text-3xl'>
							{events.isLoading ? (
								<Skeleton className='h-6 w-full' />
							) : (
								<>
									{events.data?.meta?.count || 0}

									<div className='flex flex-row items-end gap-1 text-slate-500 text-sm leading-none'>
										{t('common:currency.full', {
											val: totalDayPrice / 100,
										}).replace(',00', '')}
										<span className='text-[8px] leading-none'>HT</span>
									</div>
								</>
							)}
						</span>
					</div>

					<div className='relative z-30 flex min-w-[152px] flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l sm:border-t-0 sm:border-l'>
						<span className='text-muted-foreground text-xs'>
							Total ce mois-ci
						</span>

						<span className='flex flex-row items-end gap-1 font-bold text-lg leading-none sm:text-3xl'>
							{eventsThisMonth.isLoading ? (
								<Skeleton className='h-6 w-full' />
							) : (
								<>
									{eventsThisMonth.data?.meta?.count || 0}

									<PercentIncrease
										prev={eventsPrevMonth.data?.meta?.count}
										current={eventsThisMonth.data?.meta?.count}
									/>
								</>
							)}
						</span>
					</div>
				</div>
			</CardHeader>

			<CardContent className='flex flex-row gap-4 px-2 sm:p-6'>
				{events.isLoading ? (
					<Skeleton className='h-[250px] w-full' />
				) : (
					<ChartContainer
						config={chartConfig}
						className='aspect-auto h-[250px] w-full'
					>
						<PieChart width={400} height={400}>
							<Pie
								nameKey='name'
								dataKey='value'
								isAnimationActive={false}
								data={chartData}
								cx='50%'
								cy='50%'
								outerRadius={80}
								labelLine
								label
							/>

							<ChartTooltip
								content={
									<ChartTooltipContent className='w-[150px] capitalize' />
								}
							/>

							<ChartLegend
								content={<ChartLegendContent className='capitalize' />}
							/>
						</PieChart>
					</ChartContainer>
				)}
			</CardContent>
		</Card>
	)
}
