import { useGetMe } from '@cocoonspace/sdk-js/domains/profile/profile'
import type { GetStaticProps, NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useMemo } from 'react'
import { DescribeLayoutBody } from '~/components/layouts/describe-layout'
import { PageHeaderWrapper } from '~/components/page-header-wrapper'
import { BASE_I18N } from '~/config/base-i18n'
import { AccountsRecap } from '~/domains/home/accounts-recap'
import { EventsRecapStats } from '~/domains/home/events-recap-stats'
import { MyQuotationsRecap } from '~/domains/home/my-quotations-recap'
import { OrdersRecap } from '~/domains/home/orders-recap'
import { OverstaysRecapStats } from '~/domains/home/overstays-recap-stats'
import { UsersRecap } from '~/domains/home/users-recap'

const hellos = [
	'こんにちは',
	'안녕하세요',
	'你好',
	'Hola',
	'Bonjour',
	'Hallo',
	'Ciao',
	'Olá',
	'Hallo',
]

const emoticons = ['✨', '✊', '🐱', '💃', '🫰', '👋', '🤝', '✌️', '🫶']

const Home: NextPage = () => {
	const { data: me } = useGetMe()

	const randomHello = useMemo(
		() => hellos[Math.floor(Math.random() * hellos.length)],
		[],
	)

	const randomEmoticon = useMemo(
		() => emoticons[Math.floor(Math.random() * emoticons.length)],
		[],
	)

	return (
		<>
			<PageHeaderWrapper>
				<div className='font-bold'>
					{`${randomHello} ${me?.data.first_name} ${randomEmoticon}`}
				</div>
			</PageHeaderWrapper>

			<DescribeLayoutBody>
				<div className='grid grid-cols-1 gap-4 sm:grid-cols-3'>
					<div className='col-span-2 flex flex-col gap-4'>
						<EventsRecapStats />
						<OverstaysRecapStats />
					</div>

					<div className='col-span-1 flex flex-col gap-4'>
						<MyQuotationsRecap />
						<OrdersRecap />
						<UsersRecap />
						<AccountsRecap />
					</div>
				</div>
			</DescribeLayoutBody>
		</>
	)
}

export default Home

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
	props: {
		...(await serverSideTranslations(locale as string, BASE_I18N)),
	},
})
